import Loading from '../components/loading';
import CardProduct from '../components/card--product';
import useApi from '../hooks/useApi';
import Headbar from '../components/headbar';
import { useEffect } from 'react';

export default function Products() {
  const { Data } = useApi('external/GetProductListingAsync', true);

  return (
    <>
      {(Data) ? (
        <>
          <Headbar showBack={false} />
          <div className='flow--8'>
            <h1 className='h4 semi'>{Data.Title}</h1>
            {Data.Introduction ?
              <p dangerouslySetInnerHTML={{ __html: Data.Introduction }}></p>
              : null}
          </div>
          <div className='wrapper'>
            {Data.Products.map((product) => {
              return <CardProduct key={product.Id} data={product} />;
            })}
          </div>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}
