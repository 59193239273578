import { BrowserRouter, MemoryRouter, Routes, Route } from 'react-router-dom';
import Products from './products';
import Eligibility from './eligibility';
import EligibilityStatus from './eligibility-status';
import Checkout from './checkout';
import PersonalEdit from './edit_customer';
import VehicleEdit from './edit_vehicle';
import ProductInfo from './product-info';
import ProductQuote from './product-quote';
import PaymentResponse from './payment-response';
import MakePayment from './payment';

import '../styles/components/intro.scss';
import '../styles/checkout/container.scss';
import '../styles/components/buttons.scss';
import PaymentDetails from './payment-details';
import Slug from '../components/slug';
import ScrollToTop from '../components/scroll-to-top';

export default function Menu() {
  return (
    <>
      <MemoryRouter>
        {/* <BrowserRouter> */}
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Products />} />
          <Route path='/product/:id' element={<ProductInfo />} />
          <Route path='/product-quote/:id' element={<ProductQuote />} />
          <Route path='/eligibility/:id' element={<Eligibility />} />
          <Route path='/eligibility/status' element={<EligibilityStatus />} />
          <Route path='/checkout' element={<Checkout />} />
          <Route path='/checkout/personal' element={<PersonalEdit />} />
          <Route path='/checkout/vehicle' element={<VehicleEdit />} />
          <Route path='/payment' element={<MakePayment />} />
          <Route path='/payment/response' element={<PaymentResponse />} />
          <Route path='/payment/response/success' element={<PaymentDetails />} />
          <Route path='/slug/:id' element={<Slug />} />
        </Routes>
      </MemoryRouter>
      {/* </BrowserRouter> */}
    </>
  );
}
