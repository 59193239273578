import { useSelector } from 'react-redux';
import CheckoutRow from './checkout/row';
import { Link } from "react-router-dom";
import Loading from './loading';

export default function VehicleDetails() {
  const { Vehicle, PrePurchaseResponse } = useSelector((state) => state.checkout)

  return (
    <>
      <div className="checkout--container">
        <div className="checkout--container--header">
          <h3 className="h3">Vehicle Details</h3>
          <Link to="/checkout/vehicle" className='button button--primary'>Edit</Link>
        </div>
        <CheckoutRow label="Registration Number" value={Vehicle.RegistrationNumber} />
        <CheckoutRow label="Vehicle" value={Vehicle.Make + ' ' + Vehicle.Model} />
        <CheckoutRow label="Vehicle Price" value={Vehicle.Value} />

        {PrePurchaseResponse.VehicleDataReceived ? (
          <>
            {PrePurchaseResponse.Vehicle ? (
              <>
                <CheckoutRow label="Fuel Type" value={PrePurchaseResponse.Vehicle.FuelType} />
                <CheckoutRow label="Model" value={PrePurchaseResponse.Vehicle.Model} />
                <CheckoutRow label="Registration Date" value={PrePurchaseResponse.Vehicle.RegistrationDate} />
                <CheckoutRow label="Transmission Type" value={PrePurchaseResponse.Vehicle.Transmission} />
              </>
            ) : null}
          </>
        ) : <Loading />}
      </div>
    </>
  )
}