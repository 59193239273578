import { createSlice } from '@reduxjs/toolkit';
import Eligibility from '../pages/eligibility';

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {
    Cart: {},
    CartTotal: 0,
    Eligibility: {},
    Customer: {
      Forename: null,
      Surname: null,
      DateOfBirth: null,
      AddressL1: '',
      AddressL2: '',
      AddressL3: '',
      AddressL4: '',
      AddressL5: '',
      City: '',
      CountyRegionState: '',
      CountryCode: '',
      PostCode: '',
      MobileTelephone: null,
      Telephone: null,
      EmailAddress: null,
      Title: null,
      Type: null
    },
    Vehicle: {
      RegistrationNumber: null,
      Make: null,
      Model: null,
      Value: null,
      EngineCapacityCC: null,
      FuelType: null,
      ManufactureDate: null,
      Mileage: null,
      ModelDesc: null,
      PurchaseMethod: null,
      RegistrationDate: null,
      SaleType: null,
      Transmission: null,
      Type: null,
      TyreType: null
    },
    Policy: {
      CommencementDate: null,
      ExternalReference: null,
      OptinDate: null,
      PolicyPurchaseDate: null,
      ProductInfoHandOverDateTime: null,
      SalesDate: null,
      UserReference: null
    },
    PrePurchaseRequest: {
      RegistrationNumber: null,
      Make: null,
      Model: null,
      VehiclePrice: null,
      BasketProducts: [{
        ProductId: null,
        PricingId: null
      }]
    },
    PrePurchaseResponse: {
      VehicleDataReceived: false,
      Products: [
        {
          ProductId: null,
          TermText: null,
          Term: null,
          RateId: null,
          ProductName: null,
          WarrantyTierId: null,
          ProductTypeCode: null,
          SuggestedRetailPrice: null
        }
      ],
      Vehicle: {
        EngineCapacityCC: null,
        FuelType: null,
        ManufactureDate: null,
        Mileage: null,
        ModelDesc: null,
        PurchaseMethod: null,
        RegistrationDate: null,
        SaleType: null,
        Transmission: null,
        Type: null,
        TyreType: null
      }
    },
    CompleteRequest: {
    },
    CompleteRequestResponse: {
      Data: null
    }
  },
  reducers: {
    addProduct: (state, action) => {
      // Do not allow the same product to be added twice.
      // This prevents the basket cost doubling when running in React.StrictMode during development.
      if (state["Cart"][action.payload.id] === undefined) {
        state['CartTotal'] += action.payload.cost;
        state['Cart'][action.payload.id] = action.payload;
      }
      const basketItems = Object.keys(state.Cart).map(product => {
        return {
          ProductId: state.Cart[product].id,
          PricingId: state.Cart[product].pricingId,
        }
      });

      state.PrePurchaseRequest.BasketProducts = basketItems;
    },
    removeProduct: (state, action) => {
      state['CartTotal'] = state['CartTotal'] - state['Cart'][action.payload].cost;
      //delete state['Cart'][action.payload];

      const { [action.payload]: _, ...update } = state.Cart;

      const basketItems = Object.keys(update).map(product => {
        return {
          ProductId: state.Cart[product].id,
          PricingId: state.Cart[product].pricingId,
        }
      });

      state.PrePurchaseResponse.Products = state.PrePurchaseResponse.Products.filter(prod => prod.ProductId !== action.payload); //Directly filter the response
      state.PrePurchaseRequest.BasketProducts = basketItems;
      state.Cart = update;
    },
    setIDD: (state, action) => {
      // Confirm read IDD
      state['iddRead'] = action.payload;
    },
    setState: (state, action) => {
      // Set a value to customer or vehicle details, passed from field component
      state[action.payload.root][action.payload.key] = action.payload.value;
    },
    setStateMany: (state, action) => {
      // Allows the updating of a bunch of state at once instead of needing to invoke multiple lines of setState
      // Usage: send an object with two kvp, root: Root, and updates: [{key:key, value:value}]
      const { root, updates } = action.payload;
      if (state[root]) {
        updates.forEach(({ key, value }) => {
          state[root][key] = value;
        });
      }
    },
    setObjectState: (state, action) => {
      state[action.payload.root] = action.payload.value;
    },
    setStartDate: (state, action) => {
      // Used for updating StartDate on a specific basket item
      state[action.payload.root][action.payload.purchaseId].StartDate = action.payload.value;
    }
  },
});

export const { addProduct, removeProduct, setIDD, setState, setStateMany, setObjectState, setStartDate } = checkoutSlice.actions;
export default checkoutSlice.reducer;
