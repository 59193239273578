import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import EditField from '../components/checkout/field';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { React, useState } from 'react';
import { setState, setStateMany } from '../redux/checkout';

import { ReactComponent as ArrowRight } from '../styles/svg/ChevronRight.svg';
import '../styles/edit_vehicle.css'
import Headbar from '../components/headbar';

export default function VehicleEdit() {
  const { Vehicle } = useSelector((state) => state.checkout);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  function changeStatus() {
    var requiredFields = true;
    document.querySelectorAll("[required]").forEach(function (fieldRequired) {

      if (fieldRequired.getAttribute("value").length === 0 || fieldRequired.value === "") {
        requiredFields = false;
      }
    });

    if (requiredFields) {
      navigate('/checkout');
    }
    else {
      alert("Please make sure all required fields have a value.");
    }
  }

  function handleChange(e, target) {
    let input = e.target.value;

    if (target === "RegistrationNumber") {
      input = input.toUpperCase();
      if (/[^a-zA-Z0-9 ]/.test(input)) {
        return;
      }
    }

    dispatch(setState({ "root": "Vehicle", "key": target, "value": input }));
    if (target === "Value") {
      // For whatever reason the name is different in PrePurchase, so quickly rename the value before updating store.
      target = "VehiclePrice";
    }
    dispatch(setState({ "root": "PrePurchaseRequest", "key": target, "value": input }));
  }

  return (
    <>
      <Headbar back={"/checkout"} />
      {/* <Link to="/checkout">Back</Link> */}
      <h1 className="h1">Vehicle Details</h1>

      <div>
        <div className='vehicleDetailsReg'>
          <label htmlFor='vehicle--edit--reg'>Registration Number *</label>
          <input type='text' id='vehicle--edit--reg' value={Vehicle.RegistrationNumber ? Vehicle.RegistrationNumber : ''} required onChange={(e) => handleChange(e, "RegistrationNumber")} pattern='[a-zA-Z0-9 ]'></input>
        </div>

        <div className='vehicleDetails'>
          <label htmlFor='vehicle--edit-make'>Make *</label>
          <input type='text' id='vehicle--edit-make' value={Vehicle.Make ? Vehicle.Make : ''} required onChange={(e) => handleChange(e, "Make")}></input>
        </div>

        <div className='vehicleDetails'>
          <label htmlFor='vehicle--edit--model'>Model *</label>
          <input type='text' id='vehicle--edit--model' value={Vehicle.Model ? Vehicle.Model : ''} required onChange={(e) => handleChange(e, "Model")}></input>
        </div>

        <div className='vehicleDetails'>
          <label htmlFor='vehicle--edit--price'>Purchase Price in £ *</label>
          <input type='number' id='vehicle--edit--price' value={Vehicle.Value ? Vehicle.Value : ''} required onChange={(e) => handleChange(e, "Value")}></input>
        </div>
      </div>

      {/*
      <EditField root="Vehicle" field="RegistrationNumber" label="Registration Number *" value={Vehicle.RegistrationNumber ? Vehicle.RegistrationNumber : ''} required />
      <EditField root="Vehicle" field="Make" label="Make *" value={Vehicle.Make ? Vehicle.Make : ''} required />
      <EditField root="Vehicle" field="Model" label="Model *" value={Vehicle.Model ? Vehicle.Model : ''} required />
      <EditField root="Vehicle" field="Value" label="Purchase Price *" value={Vehicle.Value ? Vehicle.Value : ''} required />
      {/*<EditField root="vehicle" field="datePurchased" label="Date of Purchase" value={ vehicle.datePurchased ? vehicle.datePurchased : null } />*/}


      {process.env.REACT_APP_DEBUG === "true" &&
        <button onClick={() => {
          // Remove before deployment
          dispatch(setStateMany(
            {
              root: "Vehicle",
              updates: [
                { key: "RegistrationNumber", value: "DebugReg" },
                { key: "Make", value: "DebugMake" },
                { key: "Model", value: "DebugModel" },
                { key: "Value", value: 8000 }
              ]
            }
          ));
          dispatch(setStateMany({
            root: "PrePurchaseRequest",
            updates: [
              { key: "RegistrationNumber", value: "DebugReg" },
              { key: "Make", value: "DebugMake" },
              { key: "Model", value: "DebugModel" },
              { key: "VehiclePrice", value: 8000 }
            ]
          }));
          navigate('/checkout');//, { state: state });
        }}>DEBUG</button>
      }
      <button
        className='button button--primary'
        onClick={() => {
          changeStatus();
        }}>
        Continue
        <ArrowRight />
      </button>
    </>
  )
}