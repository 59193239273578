import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { setState } from '../redux/checkout';
import Loading from '../components/loading';
import { useConnect } from '../hooks/useConnect';
import useApi from '../hooks/useApi';

export default function PaymentResponse() {
  const [params] = useState(new URLSearchParams(useLocation().search));
  const { CompleteRequest } = useSelector((state) => state.checkout);
  const navigate = useNavigate();
  const { Data, error } = useApi("external/CompletePurchaseAsync", false, CompleteRequest);
  // const Data = useConnect("external/CompletePurchaseAsync", CompleteRequest, "POST", true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (Data && !error) {
      dispatch(setState({ root: "CompleteRequestResponse", key: "Data", value: Data }));
      setTimeout(() => {
        navigate("./success");
      }, 3000);
    }
  }, [Data, dispatch, navigate]);

  return (
    <>
      {error ? (
        <>
          <h2>Thank you for your purchase.</h2>
          <h2>Your policies are being processed.</h2>
          <h2>You will receive an email when registered in the next 24 hours.</h2>
          <h2>You may now close this window.</h2>
        </>
      ) :
        params.get('redirect_status') === 'succeeded' ?
          <>
            <h1>Your payment has been successful, please allow us to process your registration and you will be redirected. Please do not close or refresh this page.</h1>
            <Loading />
          </>
          :
          <h1>Something went wrong. Click <Link to={"./payment"}>here</Link> to retry payment.</h1>
      }
    </>
  );
}
