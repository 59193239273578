// Content component for rendering content blocks
// Props:
//  - title: string
//  - content: string
//  - twoCol: boolean
//  - children: any

export default function Content(props) {
  return (
    <>
      <div className='flow--12'>
        {props.title ? <h3 className='h4 semi'>{props.title}</h3> : null}
        {props.content ? <div className={props.twoCol ? 'paragraph paragraph--col-2 flow--16' : 'paragraph flow--16'} dangerouslySetInnerHTML={{ __html: props.content }}></div> : props.children}
      </div>
    </>
  );
}
