export function calculateLatestDayToBeEighteen() {
    const minimumAgeToPurchase = 18;
    const today = new Date();
    const maxBirthDate = new Date();
    maxBirthDate.setFullYear(today.getFullYear() - minimumAgeToPurchase);

    const year = maxBirthDate.getFullYear().toString();
    let month = (maxBirthDate.getMonth() + 1).toString(); // Months are 0 indexed, so we need to add 1.
    let day = maxBirthDate.getDate().toString();

    // Prefix leading 0
    if (month.length === 1) {
        month = `0${month}`;
    }

    if (day.length === 1) {
        day = `0${day}`;
    }

    //formattedDate = `${year}-${month}-${day}`;

    return `${year}-${month}-${day}`;
}