import React, { useRef, useState } from 'react';
import { setState, setStartDate } from '../../redux/checkout';
import { useDispatch } from 'react-redux';
import "../../styles/components/date.css"

export default function DatePicker(Props) {
  const [date, setDate] = useState('');
  const dateInputRef = useRef(null);
  const dispatch = useDispatch();

  let formattedDate;
  
  if (Props.preventPastDates) {
    // Don't allow dates in the past.
    formattedDate = new Date().toISOString().slice(0, 10); // -> e.g "2024-06-30"
  }

  if (Props.restrictAge) {
    // restrict max date to the age limit
    // Set via .restrictAge flag as this component is 
    // used elsewhere where it does not need to be restricted
    const minimumAgeToPurchase = 18;
    const today = new Date();
    const maxBirthDate = new Date();
    maxBirthDate.setFullYear(today.getFullYear() - minimumAgeToPurchase);
  
    const year = maxBirthDate.getFullYear().toString();
    let month = (maxBirthDate.getMonth() + 1).toString(); // Months are 0 indexed, so we need to add 1.
    let day = maxBirthDate.getDate().toString();
    
    // Prefix leading 0
    if (month.length === 1) {
      month = `0${month}`;
    }
  
    if (day.length === 1) {
      day = `0${day}`;
    }
  
    formattedDate = `${year}-${month}-${day}`;
  }

  function handleChange(event) {
    setDate(event.target.value);

    if (Props.purchaseId) {
      // If we are updating a date field for a specific item in the basket
      dispatch(setStartDate({"root": Props.root, "key": Props.field, "purchaseId": Props.purchaseId, "value": event.target.value}));
    } else {
      dispatch(setState({"root": Props.root, "key": Props.field, "value": event.target.value}));
    }
    
  }

  return (
    <div className='DateSelector'>
        <label htmlFor={ Props.field }>{ Props.label }</label>
        <input
        type="date"
        onChange={handleChange}
        ref={dateInputRef}
        value={ date ? date : Props.value }
        min={Props.preventPastDates && formattedDate}
        max={Props.restrictAge && formattedDate}
        required={ Props.required }/>
    </div>
  );
};

