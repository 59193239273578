import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useStripe } from './hooks/useStripe';
import CheckoutForm from './forms/checkout_stripe';

export default function ACLStripe() {
  const { client, params } = useStripe(100);

  return (
    <>
      { params ?
        <Elements stripe={ loadStripe(client) } options={{ clientSecret: params.client_secret }}>
          <CheckoutForm />
        </Elements>
      : null }
    </>
  );
};