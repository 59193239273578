import { useDispatch } from "react-redux";
import { addProduct, setState } from "../redux/checkout";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useConnect } from "../hooks/useConnect";
import useApi from "../hooks/useApi";
import { useEffect, useState } from "react";
import Loading from "./loading";

export default function Slug() {
    const { id } = useParams();
    const { Data, error } = useApi('external/GetProductItemAsync', true, { Id: id });
    // const Data = useConnect('external/GetProductItemAsync', { Id: id }, null, true);
    const [params] = useState(new URLSearchParams(useLocation().search));
    const [isCorrectFormat, setIsCorrectFormat] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (error) {
            setIsCorrectFormat(false);
        } else if (Data) {
            const queryStringId = params.get("id");
            const termPricing = Data.Pricing.find(term => term.Id === queryStringId);

            if (termPricing && queryStringId) {
                dispatch(addProduct({
                    id,
                    name: Data.Name,
                    cost: termPricing.Price,
                    pricingId: termPricing.Id,
                    StartDate: '',
                    // not setting eligibility as assume if email received customer must be eligible
                    checks: true
                }));

                navigate("/checkout");
            } else {
                setIsCorrectFormat(false);
            }
        }
    }, [Data, dispatch, navigate])


    if (error || !isCorrectFormat) {
        return (
            <>
                <p><h1>There was a problem adding the product to the basket.</h1></p>
                <p><h1>{error}</h1></p>
            </>
        )
    } else return <Loading />
}