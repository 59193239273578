import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setState } from '../../redux/checkout';

import '../../styles/components/field.css'

export default function EditField(Props) {
  const [value, setValue] = useState('');
  const dispatch = useDispatch();

  function handleChange(event) {
    let update = event.target.value;
    const currentChar = update.at(-1); //update.slice(-1);

    switch (Props.field) {
      case "Forename":
      case "Surname":
      case "Make":
      case "Model":
        if (/[^a-zA-Z]/.test(update)) {
          alert("You may only enter letters.");
          return;
        }
        break;
      case "MobileTelephone":
        if (/[^0-9+ ]/.test(update)) {
          alert("You may only enter numbers.");
          return;
        }
        break;
      case "RegistrationNumber":
        update = update.toUpperCase();
        if (currentChar === ' ') {
          return;
        } else if (/[^0-9a-zA-Z]/g.test(update)) {
          alert("You may only use letters and numbers.");
          return;
        }
        break;
      case "Value":
        if (/[^\d£\.]/g.test(currentChar)) {
          return;
        }
        break;
    }

    setValue(update);
    dispatch(setState({ "root": Props.root, "key": Props.field, "value": update }));
  }

  return (
    <div className="editField">
      <label htmlFor={Props.field}>{Props.label}</label>
      <input
        type="text"
        name={Props.field}
        id={Props.field}
        value={value ? value : Props.value}
        onChange={handleChange}
        required={Props.required}
        disabled={Props.disabled}
        maxLength={Props.field === "RegistrationNumber" ? 7 : null}
        autoComplete={Props.field === "RegistrationNumber" ? "off" : "on"} />
    </div>
  )
}