import { useState } from 'react';
import { Link } from 'react-router-dom';

import Content from './content';
import Section from './section';

import '../styles/components/estimate.scss';
import '../styles/components/wrapper--pricing-options.scss';

export default function Estimate(props) {
  // const [price, setPrice] = useState([props.data[0].Term, props.data[0].Price, props.data[0].Price + props.data[0].Price * 0.2]);
  const [vat, setVAT] = useState(true);

  return (
    <>
      <Section class='section--shaded'>
        <div className='estimate flow--12'>
          <Content title='Estimate' content={ props.intro ? `<p>${props.intro}</p>` : null } />
          {/* <div className='wrapper'>
            <button onClick={() => setVAT(true)} className={vat ? 'button button--select is--active' : 'button button--select'}>
              Personal
            </button>
            <button onClick={() => setVAT(false)} className={vat ? 'button button--select' : 'button button--select is--active'}>
              Business
            </button>
          </div> */}
          <div className='wrapper wrapper--pricing-options'>
            <div className='wrapper__row'>
              <div className='wrapper__term row--header'>Term</div>
              {/* TODO : @BEN Remove VAT options here... */}
              <div className='wrapper__cost row--header'>Cost {!vat ? 'ex VAT' : null}</div>
              <div className='null'></div>
            </div>
            {props.data.map((option, index) => {
              return (
                <div className='wrapper__row' key={index}>
                  <div className='wrapper__term'>{option.Term}</div>
                  <div className='wrapper__cost semi'>{new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(option.Price)}</div>
                  <Link className="button button--primary" to={"/eligibility/" + props.product[0]} state={{ id: props.product[0], name: props.product[1], cost: option.Price, pricingId: option.Id }}>Buy Now</Link>
                </div>
              );
            })}
          </div>
        </div>
      </Section>
    </>
  );
}
