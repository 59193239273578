import { useState, useEffect } from 'react';
import { setKey } from '../redux/auth.js';
import { useDispatch, useSelector } from 'react-redux';
import serialise from '../helpers/serialise.js';

export function useConnect(endpoint, data = null, method = "GET", debug = false) {
  const { license, key, expires } = useSelector((state) => state.authorise)
  const dispatch = useDispatch();
  const [aclData, setaclData] = useState();

  if(debug) { 
    console.log(aclData)
  }  

  useEffect(() => {
    if(license && !aclData) {

      // If a key isn't set, get one and store it in /redux/auth.js
      if( !key && new Date(Date.now()) >= new Date(expires) ) {
        fetch("https://staging.gi-plugin-php.automotivecreditcompliance.com" + "/refresh/index.php?"
          + ( license ? `license=${license}` : '' ), 
        { method: "GET" })
          .then(response => response.json())
          .then(data => dispatch(setKey(data)))
          .catch(err => console.log(err))
      }          
      
      //Once a key is set, go and fetch the data
      if( key && new Date(Date.now()) < new Date(expires) ) {
        if (method === "POST") {
          // current php code requires the key be sent in query param
          // no need to send &method=POST
          
          fetch("https://staging.gi-plugin-php.automotivecreditcompliance.com" + "/connect/index.php?" + `endpoint=${endpoint}` + `&key=${key}`, {
            headers: {
              "Content-Type": "application/json" //"application/x-www-form-urlencoded"
            },
            method: "POST",
            body: JSON.stringify(data)//serialise(data)
          }).then(response => response.json())
            .then(data => setaclData(data))
            .catch(err => console.log(err));
        } else {
          fetch("https://staging.gi-plugin-php.automotivecreditcompliance.com" + "/connect/index.php?"
            + ( endpoint ? `endpoint=${endpoint}` : '' )
            + ( data ? `&data=${encodeURIComponent(JSON.stringify(data))}` : '' )
            + ( key ? `&key=${key}` : '' ),
            //+ ( method ? `&method=${method}` : '' ),
          { method: "GET" })
            .then(response => response.json())
            .then(data => setaclData(data))
            .catch(err => console.log(err))
        }

      }
    }
  }, [aclData, endpoint, data, method, key, expires, dispatch, license])

  return aclData
}