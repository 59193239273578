import { useState, useEffect } from "react";

const fetchParams = {
  method: "GET",
  mode: 'cors',
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  }
}
 
export function useLicense( key ) {
  const [data, setData] = useState()

  useEffect(() => {
    if( key && !data ) {
      fetch("https://staging.gi-plugin-php.automotivecreditcompliance.com" + "/sites/index.php?"
        + ( key ? `dealer=${key}` : '' ), 
      fetchParams )
        .then(response => response.json())
        .then(data => setData(data))
        .catch(err => console.log(err))
    }
  }, [key, data])

  return data;
}