import { useDispatch, useSelector } from 'react-redux';
import { setState, setStateMany } from '../redux/checkout';

import { Link } from "react-router-dom";
import AddressLookup from '../components/checkout/address-lookup';
import EditField from '../components/checkout/field';
import DropdownChange from '../components/checkout/dropdown';
import DatePicker from '../components/checkout/date';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as ArrowRight } from '../styles/svg/ChevronRight.svg';
import { useEffect } from 'react';
import "../styles/edit_customer.css"
import { calculateLatestDayToBeEighteen } from '../helpers/misc';
import Headbar from '../components/headbar';

export default function PersonalEdit() {
  const { Customer, Vehicle, Cart, PrePurchaseResponse } = useSelector((state) => state.checkout);
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const optinDate = new Date().toISOString();
    const reference = `Acl-${new Date().toISOString()}`;
    const products = [];

    Object.keys(Cart).forEach(product => {
      const { MaxSingleClaim, ProductTypeCode, Provider, RateId, Term, WarrantyTierId = null } = PrePurchaseResponse.Products.find(({ ProductId }) => ProductId === Cart[product].id);
      const { name: ProductName, cost: RetailPrice, StartDate } = Cart[product];

      products.push({
        MaxSingleClaim,
        PaymentMethod: "FreeToCustomer",
        ProductName,
        ProductTypeCode,
        Provider,
        RateId,
        RetailPrice,
        StartDate,
        Term,
        WarrantyStartDate: Cart[product].StartDate, // Check if this can be different than start date
        WarrantyTierId,
      });
    });

    dispatch(setStateMany(
      {
        root: "CompleteRequest",
        updates: [
          { key: "Customer", value: Customer },
          { key: "Vehicle", value: Vehicle },
          { key: "Products", value: products },
          { key: "OptinDate", value: optinDate },
          { key: "ProductInfoHandOverDateTime", value: optinDate },
          { key: "SalesDate", value: optinDate },
          { key: "CommencementDate", value: optinDate },
          { key: "PolicyPurchaseDate", value: optinDate },
          { key: "ExternalReference", value: reference },
          { key: "UserReference", value: "" }
        ]
      }
    ));
  }, [Customer])

  const customerType = Object.freeze({
    'Please Select': '',
    Private: 'Private',
    Company: 'Company'
  })

  const customerTitle = Object.freeze({
    'Please Select': '',
    Mr: 'Mr',
    Ms: 'Ms',
    Miss: 'Miss',
    Mrs: 'Mrs',
    Dr: 'Dr',
    Sir: 'Sir',
    Rev: 'Rev'
  })

  function changeStatus() {
    var requiredFields = true;
    document.querySelectorAll("[required]").forEach(function (fieldRequired) {
      if (fieldRequired.value === 'Please Select') {
        requiredFields = false;
      }
      else if (fieldRequired.value.length === 0) {
        requiredFields = false;
      }
    });

    if (requiredFields) {
      navigate('/payment', { state: state });
    }
    else {
      alert("Please make sure all required fields have a value.");
    }
  }

  function validateEmail() {
    return true;
    // The email is only validated on clicking "Continue",
    // to allow the user to type it all in. The other fields are validated
    // char by char during handleChange in EditField component.

    // Matches email addresses as described in RFC 2822
    const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g

    const email = document.getElementById("EmailAddress").value;
    const emailIsValidFormat = emailRegex.test(email);

    if (!emailIsValidFormat) {
      alert("The email address is not formatted correctly.");
      return false;
    }

    dispatch(setState({ "root": "Customer", "key": "EmailAddress", "value": email }));
    return true;
  }

  function formatPhoneNumber() {
    let phoneNumber = document.getElementById("MobileTelephone").value;

    // Convert + to 00, strip whitespace
    phoneNumber = phoneNumber.replaceAll(/\+/g, "00");
    phoneNumber = phoneNumber.replaceAll(/ /g, '');

    dispatch(setState({ "root": "Customer", "key": "MobileTelephone", "value": phoneNumber }));
  }

  function onChangeDate(e) {
    dispatch(setState({ "root": "Customer", "key": "DateOfBirth", "value": e.target.value }));
  }

  return (
    <>
      <Headbar back={"/checkout"} />
      <h1 className="h1">Personal Details</h1>

      <DropdownChange root="Customer" field="Type" label="Private / Business *" value={customerType} required />
      <DropdownChange root="Customer" field="Title" label="Title *" value={customerTitle} required />
      <EditField root="Customer" field="Forename" label="Firstname *" value={Customer.Forename ? Customer.Forename : ''} required />
      <EditField root="Customer" field="Surname" label="Surname *" value={Customer.Surname ? Customer.Surname : ''} required />
      <div className='editDob'>
        <label>
          Date of Birth *
        </label>
        <input type="date" value={Customer.DateOfBirth ? Customer.DateOfBirth : ''} max={calculateLatestDayToBeEighteen()} onChange={(e) => onChangeDate(e)} required></input>
      </div>
      {/* <DatePicker root="Customer" field="DateOfBirth" label="Date of Birth *" value={Customer.DateOfBirth ? Customer.DateOfBirth : ''} restrictAge={true} required /> */}
      <AddressLookup required />
      <EditField root="Customer" field="MobileTelephone" label="Primary Phone *" value={Customer.MobileTelephone ? Customer.MobileTelephone : ''} required />
      <EditField root="Customer" field="EmailAddress" label="Email Address *" value={Customer.EmailAddress ? Customer.EmailAddress : ''} required />

      <EditField root="Customer" field="CountryCode" label="Country Code " value={"GBP"} disabled={true} />

      {process.env.REACT_APP_DEBUG === "true" &&
        <button onClick={() => {
          // Remove before deployment
          dispatch(setStateMany({
            root: "Customer",
            updates: [
              { key: "Type", value: "Private" },
              { key: "Title", value: "Mr" },
              { key: "Forename", value: "DEBUGFNAME" },
              { key: "Surname", value: "DEBUGSNAME" },
              { key: "DateOfBirth", value: "2000-01-01" },
              { key: "MobileTelephone", value: "07000000000" },
              { key: "EmailAddress", value: "test@test.com" },
              { key: "CountryCode", value: "GBP" },
              { key: "AddressL1", value: "TESTLINE1" },
              { key: "AddressL2", value: "TESTL2" },
              { key: "AddressL3", value: "TESTL3" },
              { key: "City", value: "TESTCITY" },
              { key: "CountyRegionState", value: "COUNTY" },
              { key: "PostCode", value: "GU14HR" }
            ]
          }));
        }}>
          DEBUG
        </button>
      }
      <button
        className='button button--primary'
        onClick={() => {
          if (validateEmail()) {
            formatPhoneNumber();
            changeStatus();
          }
        }}>
        Continue
        <ArrowRight />
      </button>
    </>
  )
}