import React from 'react';
import ReactDOM from 'react-dom/client';
import LicenseCheck from './licenseCheck';
import { Provider } from 'react-redux';
import store from './redux/store';

const entry = document.getElementById('acl');
ReactDOM.createRoot(entry).render(
  <React.StrictMode>
    <Provider store={store}>
      <LicenseCheck license={entry.getAttribute('data-license')} />
    </Provider>
  </React.StrictMode>
);
