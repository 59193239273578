// import { ReactComponent as Tick } from '../styles/svg/Tick.svg';
import Content from './content.js';

import '../styles/components/ulticklist.scss';
import '../styles/components/eligibility.scss';

export default function Eligibility(props) {
  return (
    <div className='eligibility'>
      { props.data.map((column, i) => {
        return (
          <>
            <div className='eligibility__wrapper flow--12'>
              <Content title={ column.Title } content={ column.Description } />
            </div>

            { i === 0  && props.data.length > 1 ?
              <div className='eligibility__divider'></div>
            : null }
          </>
        )
      })}
    </div>
  );
}
