import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useConnect } from '../hooks/useConnect';
import useApi from '../hooks/useApi';
import Headbar from '../components/headbar';

export default function Eligibility() {
  const { id } = useParams();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(true);
  // const data = useConnect('external/GetEligibilityAsync', { Id: id });
  const { Data } = useApi('external/GetEligibilityAsync', false, { Id: id });

  if (Data) {
    state.eligibility = Data.Eligibility;

    state.eligibility.map((item, key) => {
      if (state.eligibility[key].label) return
      state.eligibility[key] = { "label": item, "state": false }
    })
  }

  function setCheck(key) {
    state.eligibility[key].state = !state.eligibility[key].state;

    const hasFalseBool = obj => obj.state === false;
    const result = state.eligibility.some(hasFalseBool);

    if (result) {
      state.checks = false
      setIsDisabled(true)
    } else {
      state.checks = true
      setIsDisabled(false)
    }
  }

  // General 'Yes' or 'No' confirmation for overal eligibility saved to Redux data store
  function changeStatus(status) {
    state.checks = status
    navigate('/eligibility/status', { state: state });
  }

  return (
    <>
      <Headbar back={`/product/${state.id}`} />
      <div className='flow--32'>
        <h3 className='h3'>Eligibility questionaire</h3>
        <p>To be eligibile for our products you must confirm that all of the below apply.</p>

        <div className='flow--16'>
          {state?.eligibility?.length > 0 ?
            state.eligibility.map((check, index) => {
              return (
                <div key={index} className='checks'>
                  <input
                    className='checkboxNative'
                    type='checkbox'
                    name={'checkbox--' + index}
                    id={'checkbox--' + index}
                    defaultChecked={check.state}
                    onChange={() => { setCheck(index) }}
                  />
                  <label htmlFor={'checkbox--' + index} className='eligibility--checks'>{check.label}</label>
                </div>
              )
            })
            : null}
        </div>
      </div>

      <div className='button--wrap button--wrap-row'>
        {process.env.REACT_APP_DEBUG === "true" && <button onClick={() => changeStatus(true)}>DEBUG</button>}
        <button
          disabled={isDisabled}
          className='button button--secondary'
          onClick={() => {
            changeStatus(true);
          }}>
          Yes
        </button>
        <button
          className='button button--secondary'
          onClick={() => {
            changeStatus(false);
          }}>
          No
        </button>
      </div >
    </>
  )
}