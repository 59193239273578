import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import VehicleDetails from '../components/vehicle';
import CartProducts from '../components/incart';
import { useEffect, useState } from 'react';

import '../styles/components/checkbox.scss';
import { ReactComponent as ArrowRight } from '../styles/svg/ChevronRight.svg';
import RateDetails from '../components/rate';

import { setStateMany } from '../redux/checkout';
import useApi from '../hooks/useApi';
import Headbar from '../components/headbar';

export default function Checkout() {
  const { Vehicle, Cart, PrePurchaseRequest, PrePurchaseResponse } = useSelector((state) => state.checkout);
  const vehicleDetailsComplete = Vehicle.RegistrationNumber && Vehicle.Make && Vehicle.Model && Vehicle.Value;

  //only fetch PrePurchase if the vehicle has been entered and there are products in the cart
  const { Data } = useApi((vehicleDetailsComplete && Object.keys(Cart).length > 0) ? 'external/PrePurchaseAsync' : null, false, PrePurchaseRequest);
  const dispatch = useDispatch();
  const { state } = useLocation();


  console.log(state)

  useEffect(() => {
    if (Data) {
      let newTransmission = Data.Vehicle.Transmission;
      if (newTransmission === "Manual gearbox") {
        newTransmission = "Manual";
      }

      dispatch(setStateMany({
        root: "PrePurchaseResponse",
        updates: [
          { key: "VehicleDataReceived", value: true },
          { key: "Products", value: Data.Products },
          { key: "Vehicle", value: Data.Vehicle }
        ]
      }));

      dispatch(setStateMany({
        root: "Vehicle",
        updates: [
          { key: "EngineCapacityCC", value: Data.Vehicle.EngineCapacityCC },
          { key: "FuelType", value: Data.Vehicle.FuelType },
          { key: "ManufactureDate", value: Data.Vehicle.ManufactureDate },
          { key: "Mileage", value: Data.Vehicle.Mileage },
          { key: "ModelDesc", value: Data.Vehicle.ModelDesc },
          { key: "PurchaseMethod", value: Data.Vehicle.PurchaseMethod },
          { key: "RegistrationDate", value: Data.Vehicle.RegistrationDate },
          { key: "SaleType", value: Data.Vehicle.SaleType },
          { key: "Transmission", value: newTransmission },
          { key: "Type", value: Data.Vehicle.Type },
          { key: "TyreType", value: Data.Vehicle.TyreType }
        ]
      }));
    }
  }, [Data, dispatch, Cart]);

  const disabledButton =
    <>
      <button disabled={true} className='button button--primary'>
        Continue
        <ArrowRight />
      </button>
    </>

  return (
    <>
      <Headbar back={state === null ? '/' : `/product/${state}`} />
      <h1 className='h1'>Order Summary</h1>
      {vehicleDetailsComplete && <VehicleDetails />}

      <CartProducts />

      <div className="wrapper">
        {(Object.keys(Cart).length > 0) ?
          vehicleDetailsComplete ? PrePurchaseResponse.VehicleDataReceived ? (Object.keys(Cart).every(id => Cart[id]["StartDate"] !== '')) ?
            // Items in cart, vehicle data set, PrePurchase received, and all items dated
            <Link to='/checkout/personal' className='button button--primary'>
              Continue
              <ArrowRight />
            </Link>
            :
            // Products in cart, vehicle data set, PrePurchase received, but still need dates
            disabledButton
            :
            // Products in cart, and vehicle data set. But still waiting for Prepurchase and dates
            disabledButton
            :
            // Products in cart but no vehicle yet - so link to vehicle details input
            <Link to='/checkout/vehicle' className='button button--primary'>
              Continue
              <ArrowRight />
            </Link>
          : null //No products in cart
        }
        <Link to='/' className='button button--secondary'>
          Return to Menu
          <ArrowRight />
        </Link>
      </div>
    </>
  );
}
