import { useSelector, useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { removeProduct } from '../redux/checkout';
import DatePicker from '../components/checkout/date';
import { ReactComponent as Delete } from '../styles/svg/cross.svg';
import CheckoutRow from './checkout/row';
import { useState, useEffect } from 'react';
import { setState } from '../redux/checkout';

export default function CartProducts() {
  const { Cart, CartTotal, Vehicle, PrePurchaseResponse } = useSelector((state) => state.checkout);
  const dispatch = useDispatch();
  const vehicleDetailsComplete = Vehicle.RegistrationNumber && Vehicle.Make && Vehicle.Model && Vehicle.Value;

  return (
    <>
      <div className='checkout--container'>
        <div className="checkout--container--header">
          <h3 className="h3">Basket Products</h3>
        </div>

        {Object.keys(Cart).length != 0 ? (
          <>
            {Object.keys(Cart).map((product, index) => {
              return (
                <div key={`CartProductItem${index}`}>
                  <div className='product'>
                    <span className='product--name'><Link to={`/product/${Cart[product].id}`}>{Cart[product].name}</Link></span>
                    <span className='product--cost'>{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(Cart[product].cost)}</span>
                    <button
                      onClick={() => {
                        dispatch(removeProduct(Cart[product].id));
                      }}>
                      <Delete />
                    </button>
                  </div>
                  {vehicleDetailsComplete ? (
                    PrePurchaseResponse.VehicleDataReceived && <>
                      <div style={{ marginLeft: 10 }} className='product--startDate'>
                        <DatePicker purchaseId={Cart[product].id} root="Cart" field="StartDate" label="Policy Start Date* " value={Cart[product].StartDate ? Cart[product].StartDate : ''} preventPastDates={true} required />
                      </div>
                    </>
                  ) : null}

                  {PrePurchaseResponse ? (
                    <>
                      {PrePurchaseResponse.Products ? (
                        <>
                          {PrePurchaseResponse.Products.find(({ ProductId }) => ProductId === Cart[product].id) ? (
                            <div style={{ marginLeft: 20 }}>
                              <CheckoutRow label="Term" value={PrePurchaseResponse.Products.find(({ ProductId }) => ProductId === Cart[product].id)['Term']} />
                              <CheckoutRow label="MaxSingleClaim" value={PrePurchaseResponse.Products.find(({ ProductId }) => ProductId === Cart[product].id)['MaxSingleClaim']} />
                            </div>
                          ) : null}
                        </>
                      ) : null}
                    </>
                  ) : null}
                </div>
              );
            })}

            <div className='summary'>
              {/* <div className='summary__line'>
                <span className='label'>Subtotal:</span>
                <span className='value'>{new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(cartTotal)}</span>
              </div>

              <div className='summary__line'>
                <span className='label'>VAT:</span>
                <span className='value'>{new Intl.NumberFormat('en-GB', {style: 'currency', currency: 'GBP'}).format(cartTotal * 0.2)}</span>
              </div> */}

              <div className='summary__line'>
                <span className='label'>Total:</span>
                <span className='value bold'>{new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(CartTotal)}</span>
              </div>

              <div className='summary__line'>
                <span className="disclaimer">Prices include Insurance Premium Tax</span>
              </div>
            </div>
          </>
        ) : (
          <p>
            There are no products in the cart. Would you like to <Link to='/'>add some</Link>?
          </p>
        )}
      </div>
    </>
  );
}
