import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setKey } from "../redux/auth.js";

const cache = new Map();

export default function useApi(endpoint, memoize = false, data = null) {
    const { license, key, expires } = useSelector((state) => state.authorise);
    const dispatch = useDispatch();
    const [apiData, setAPIData] = useState(null);
    const [error, setError] = useState('');

    let method;
    let headers;
    let body;
    let memoKey = JSON.stringify(data);
    const rootURL = "https://staging.gi-plugin-php.automotivecreditcompliance.com" + "/connect/index.php?"

    // No need to remember what endpoints require what methods if we set them here
    switch (endpoint) {
        case "external/GetProductItemAsync":
        case "external/GetEligibilityAsync":
        case "external/GetProductListingAsync":
            method = "GET";
            break;
        case "external/PrePurchaseAsync":
        case "external/CompletePurchaseAsync":
            method = "POST"
            headers = {
                "Content-Type": "application/json"
            }
            body = JSON.stringify(data);
            break;
    }

    const setCache = (endpoint, data, result) => {
        if (data) {
            if (!cache.has(endpoint)) {
                cache.set(endpoint, new Map([[memoKey, result]]));
            } else {
                cache.get(endpoint).set(memoKey, result)
            }
        } else {
            cache.set(endpoint, result);
        }
    };
    const getCache = (endpoint, data) => {
        if (!cache.has(endpoint)) return null;
        if (data) return cache.get(endpoint).get(memoKey) ?? null;

        return cache.get(endpoint);
    };

    useEffect(() => {
        //console.log("useApi", endpoint, data)
        if (!endpoint) return;
        if (memoize && getCache(endpoint, data)) {
            //console.log("useApi had cache ", endpoint);
            setAPIData(getCache(endpoint, data));
            return;
        } else
            if (license && !apiData) {
                if (!key && new Date(Date.now()) >= new Date(expires)) {
                    fetch("https://staging.gi-plugin-php.automotivecreditcompliance.com" + "/refresh/index.php?"
                        + (license ? `license=${license}` : ''),
                        { method: "GET" })
                        .then(response => response.json())
                        .then(data => dispatch(setKey(data)))
                        .catch(err => console.log(err))
                }

                if (key && new Date(Date.now()) < new Date(expires)) {

                    const url = rootURL
                        + `endpoint=${endpoint}`
                        + (method === "GET" && data ? `&data=${encodeURIComponent(JSON.stringify(data))}` : '')
                        + (key ? `&key=${key}` : '');

                    const options = {
                        method,
                        ...(headers && { headers }),  // Add headers only if they are needed (POST)
                        ...(method === "POST" && data && { body })  // Add body only if method is POST and data is provided
                    };

                    //console.log("useApi fetching ", endpoint, data)

                    fetch(url, options)
                        .then(response => response.json())
                        .then(result => {
                            if (result.Errors) {
                                throw new Error(result.Title);
                            } else {
                                memoize && setCache(endpoint, data, result);
                                setAPIData(result)
                            }

                        })
                        .catch(ex => {
                            setError(ex.message);
                        })
                }
            }

    }, [endpoint, data, memoize, license, key, expires, dispatch])

    return { Data: apiData, error };
}