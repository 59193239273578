import { Link } from 'react-router-dom';
import { ReactComponent as ArrowRight } from '../styles/svg/ArrowRight.svg';
import { ReactComponent as Tick } from '../styles/svg/tick.svg';

import '../styles/components/product/index.scss';
import '../styles/components/ulticklist.scss';

export default function ProductCard(props) {
  return (
    <div className='card card--product'>
      <div className='card__overlay'></div>
      <div className='card__thumbnail'>
        <img src={props.data.ImageUrl} alt={props.data.Name} />
      </div>

      <div className='card__data'>
        <h2 className='h2 bold'>{props.data.Name}</h2>
        <ul className='ulticklist standout'>
          {props.data.KeyFacts.slice(0, 3).map((fact, index) => {
            return (
              <li key={index} className='ulticklist--icon-light'>
                <Tick />
                <span>{fact}</span>
              </li>
            );
          })}
        </ul>

        <div className='wrapper'>
          <Link className='button button--primary' to={'/product/' + props.data.Id}>
            <span className='label'>More Information</span>
            <ArrowRight />
          </Link>
        </div>
      </div>
    </div>
  );
}
