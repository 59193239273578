import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { addProduct } from '../redux/checkout';

import { ReactComponent as Success } from '../styles/svg/status-success.svg';
import { ReactComponent as Failed } from '../styles/svg/status-failed.svg';
import { ReactComponent as ArrowRight } from '../styles/svg/ChevronRight.svg';
import Headbar from '../components/headbar';

export default function EligibilityStatus() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (state.checks) {
      console.log(state)
      dispatch(addProduct(state));
      navigate('/checkout', { state: state.id });
    }
  }, [state])

  function handleClick() {
    if (state.checks) {
      dispatch(addProduct(state));
      navigate('/checkout', { state: state.id });
    } else {
      navigate('/');
    }
  }

  return (
    <>
      {state.checks ? (
        <>
          <div className="flow--16">
            <Success />
            <h3 className='h3'>Success</h3>
            <div className='button-wrap'>
              <button className="button button--primary" onClick={handleClick}>
                <span className="label">Continue</span>
                <ArrowRight />
              </button>
            </div>
          </div>
        </>
      ) : (
        <div className="flow--16">
          <Failed />
          <h3 className='h3'>You are not eligibile for this product.</h3>
          <div className='button-wrap'>
            <button className="button button--primary" onClick={handleClick}>
              <span className="label">Return Home</span>
              <ArrowRight />
            </button>
          </div>
        </div>
      )}
    </>
  );
}
