import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ReactCompareImage from 'react-compare-image';
import { Player, ControlBar, BigPlayButton } from 'video-react';
// import ReactPlayer from 'react-player'
import { Worker, Viewer } from '@react-pdf-viewer/core';

import Loading from '../components/loading';
import FAQ from '../components/faq';
import HeadBar from '../components/headbar';
import Estimate from '../components/estimate--product-item';
import Content from '../components/content';
import Section from '../components/section';
import Eligibility from '../components/eligibility-covered-callout';

import { ReactComponent as Tick } from '../styles/svg/tick.svg';

import '../styles/components/product/index.scss';
import '../styles/components/ulticklist.scss';
import '../styles/components/paragraph.scss';
import '../styles/components/before-after.scss';
import 'video-react/dist/video-react.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import useApi from '../hooks/useApi';

export default function ProductInfo() {
  const { id } = useParams();

  // const Data = useConnect('external/GetProductItemAsync', { Id: id }, null, true);
  const { Data } = useApi("external/GetProductItemAsync", true, { Id: id });

  const { Cart } = useSelector((state) => state.checkout);
  const [canbuy] = useState(Cart[id] ? false : true);

  return (
    <>
      {(Data) ? (
        <>
          <HeadBar back='/' />
          <div className='flow--4'>
            <h1 className='h1 bold'>{Data.Name}</h1>
            {Data.TagLine ? <h2 className='h4 semi primary--200'>{Data.TagLine}</h2> : null}
          </div>
          <Section class='section--shaded'>
            <div className='features features--product'>
              <div className='features__ul'>
                <h2 className='h4 semi'>Key Features</h2>
                <ul className='ulticklist'>
                  {Data.KeyFacts.map((fact, index) => {
                    return (
                      <li key={`Data.KeyFacts${index}`}>
                        <Tick />
                        <span>{fact}</span>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {Data.ImageUrl ? (
                <div className='features__thumbnail'>
                  <picture className='ratio--4by3'>
                    <img src={Data.ImageUrl} alt={Data.Name + ' | ' + Data.TagLine} />
                  </picture>
                </div>
              ) : null}
            </div>
          </Section>
          <Content twoCol content={Data.Description} />

          {Data.CoverDescription ?
            <section className='callout'>
              {Data.SpanImageUrl ? (
                <div className='callout__thumbnail'>
                  {!Data.SpanImageUrl && Data.SpanMediaVideoCoverUrl ?
                    <picture className="ratio-16by9">
                      <img src={Data.SpanMediaVideoCoverUrl} />
                    </picture>
                    :
                    <>
                      <div style={{ paddingTop: "75px" }}>
                        {/* <ReactPlayer url={Data.SpanImageUrl} controls={true} playing={false} volume={1} muted={true} width={"100%"} height={"100%"} /> */}
                        <Player playsInline preload="auto" {...Data.SpanMediaVideoCoverUrl ? { poster: Data.SpanMediaVideoCoverUrl } : {}} aspectRatio='16:9' src={Data.SpanImageUrl}>
                          <BigPlayButton position='center' />
                          <ControlBar autoHide={true} />
                        </Player>
                      </div>
                    </>
                  }
                </div>
              ) : null}

              {Data.CoverDescriptionTitle ?
                <Content>
                  <h3 className='h4 semi mt-32'>{Data.CoverDescriptionTitle}</h3>
                </Content>
                : null}

              {Data.CoverDescription || Data.CoverDescription ? (
                <div className='callout__cover mt-32 auto__grid'>
                  {Data.CoverDescription.map((CoverDescription, index) => {
                    return (
                      <div key={`Data.CoverDescription${index}`} className='callout__cover__item flow--16'>
                        <h4 className='h5 semi'>
                          <img src={CoverDescription.ImageUrl} alt={CoverDescription.Title} />
                          {CoverDescription.Title}
                        </h4>
                        <Content content={CoverDescription.Description} />
                      </div>
                    );
                  })}
                </div>
              ) : null}
            </section>
            : null}

          {Data.BeforeAndAfterImages ? (
            <div className='before-after flow--12'>
              <h3 className='h4 semi'>{Data.Title}</h3>
              <div className='before-after__img'>
                {Data.BeforeAndAfterImages.map((BeforeAndAfterImages, index) => {
                  return (
                    <div key={`Data.BeforeAndAfterImages${index}`}>
                      {BeforeAndAfterImages.BeforeUrl && BeforeAndAfterImages.AfterUrl ?
                        <ReactCompareImage leftImage={BeforeAndAfterImages.BeforeUrl} rightImage={BeforeAndAfterImages.AfterUrl} />
                        :
                        <img width="100%" src={BeforeAndAfterImages.BeforeUrl} />
                      }
                    </div>
                  );
                })}
              </div>
            </div>
          ) : null}

          {canbuy ? <Estimate product={[id, Data.Name]} intro={Data.PricingDescription ? Data.PricingDescription : null} data={Data.Pricing} /> : null}

          {Data.SupplementaryInfo ? <Content title='Supplementary information' content={Data.SupplementaryInfo} /> : null}

          {Data.PolicyInfo?.length > 0 ?
            <Section class='section--shaded'>
              <Eligibility data={Data.PolicyInfo} />
            </Section>
            : null}

          {Data.IpidUrl ?
            <section>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js">
                <Viewer fileUrl={Data.IpidUrl} />
              </Worker>
            </section>
            : null}

          {Data.Faqs.length > 0 ? (
            <div className='faq'>
              <div className='faq__title'>
                <h3 className='h4 semi'>FAQs</h3>
              </div>
              {Data.Faqs.map((faq, index) => {
                return <FAQ key={`Data.Faqs${index}`} data={faq} />;
              })}
            </div>
          ) : null}

          {/* {canbuy ? <EstimateBar id={id} name={Data.Name} /> : null} */}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
}
