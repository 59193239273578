import { useState } from 'react';
import { ReactComponent as Cross } from '../styles/svg/cross.svg';
import '../styles/components/faq.scss';

export default function FAQ({ data }) {
  const [view, setView] = useState(false);

  function toggleView() {
    setView(!view);
  }

  return (
    <div className={view ? 'faq is--active' : 'faq'} onClick={toggleView}>
      <div className='faq__title'>
        <span>{data.Question}</span>
        <Cross />
      </div>
      {view ? (
        <>
          <div className='faq__answer'>
            <p>{data.Answer}</p>
          </div>
        </>
      ) : null}
    </div>
  );
}
