import { useState } from 'react';
import { useSelector } from 'react-redux';
import EditField from './field';
// import { usePAF } from '../../hooks/usePAF';
import { setStateMany } from '../../redux/checkout';
import { useDispatch } from 'react-redux';

import getAddressesFromPostcode from '../../helpers/postcodeFetch';

export default function AddressLookup() {
  const { Customer } = useSelector((state) => state.checkout)
  const [PostCode, setPostcode] = useState('');
  const [addresses, setAddresses] = useState(null);
  const dispatch = useDispatch();

  function handleChange(e) {
    if (e.target.value === "Please Select...") {
      return;
    }

    const selectedAddress = addresses.addresses[e.target.value];
    const [AddressL1, AddressL2, AddressL3, City, CountyRegionState] = selectedAddress.formatted_address;

    dispatch(setStateMany({
      root: "Customer",
      updates: [
        { key: "AddressL1", value: AddressL1 },
        { key: "AddressL2", value: AddressL2 },
        { key: "AddressL3", value: AddressL3 },
        { key: "City", value: City },
        { key: "CountyRegionState", value: CountyRegionState },
        { key: "PostCode", value: PostCode }
      ]
    }));
  }

  return (
    <>
      <div className='PostCodeSearchContainer'>
        <label>
          PostCode Lookup:
        </label>

        <div className='PostCodeSearchControls'>
          <input type="text" name="PostCode" value={PostCode || ''} onChange={(e) => {
            const toUpper = e.target.value.toUpperCase();
            const validValue = toUpper.replace(/[^ A-Z0-9]/g, ''); // Remove forbidden characters
            setPostcode(validValue);
          }} />
          <button className="button button--primary" onClick={() => {
            getAddressesFromPostcode(PostCode.replaceAll(/[ ]/g, ''), true).then(data => setAddresses(data))
          }}>Search</button>
        </div>

        <div className="PostCodeSearchAddresses">
          {addresses ?
            <select onChange={(e) => handleChange(e)}>
              <option>Please Select...</option>
              {addresses.addresses.map((address, index) => {
                return (
                  <option key={index} value={index}>{address.line_1}</option>
                )
              })}
            </select>
            : null}
        </div>
      </div>

      {/* { address ?
      <>
      <EditField root="Customer" field="AddressL1" label="Address Line One *" value={address.AddressL1} required/>
      <EditField root="Customer" field="AddressL2" label="Address Line Two " value={address.AddressL2} />
      <EditField root="Customer" field="AddressL3" label="Address Line Three " value={address.AddressL3} />
      <EditField root="Customer" field="City" label="City *" value={address.City} required/>
      <EditField root="Customer" field="CountyRegionState" label="County *" value={address.CountyRegionState} required/>
      <EditField root="Customer" field="PostCode" label="Postcode *" value={PostCode} required/>
      </>
    :
      <>
      <EditField root="Customer" field="AddressL1" label="Address Line One *" value={ Customer.AddressL1 ? Customer.AddressL1 : '' } required/>
      <EditField root="Customer" field="AddressL2" label="Address Line Two" value={ Customer.AddressL2 ? Customer.AddressL2 : '' } />
      <EditField root="Customer" field="AddressL3" label="Address Line Three" value={ Customer.AddressL3 ? Customer.AddressL3 : '' } />
      <EditField root="Customer" field="City" label="City *" value={ Customer.City ? Customer.City : '' } required/>
      <EditField root="Customer" field="CountyRegionState" label="County *" value={ Customer.CountyRegionState ? Customer.CountyRegionState : '' } required/>
      <EditField root="Customer" field="PostCode" label="Postcode *" value={ Customer.PostCode ? Customer.PostCode : '' } required/>
      </>
    } */}

      <>
        <EditField root="Customer" field="AddressL1" label="Address Line One *" value={Customer.AddressL1} required />
        <EditField root="Customer" field="AddressL2" label="Address Line Two" value={Customer.AddressL2} />
        <EditField root="Customer" field="AddressL3" label="Address Line Three" value={Customer.AddressL3} />
        <EditField root="Customer" field="City" label="City *" value={Customer.City} required />
        <EditField root="Customer" field="CountyRegionState" label="County *" value={Customer.CountyRegionState} required />
        <EditField root="Customer" field="PostCode" label="Postcode *" value={Customer.PostCode} required />
      </>
    </>
  )
}