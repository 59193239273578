import { useSelector } from "react-redux"
import ACLStripe from '../gateways/stripe'
import { Link } from "react-router-dom"
import Headbar from "../components/headbar"

export default function MakePayment() {
  const { gateway } = useSelector((state) => state.authorise)
  const { CartTotal } = useSelector(state => state.checkout)

  return (
    <>
      <Headbar back={"/checkout"} />
      <h1>Make a payment of {new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(CartTotal)}</h1>
      {gateway === 'stripe' ?
        <ACLStripe />
        : null}
    </>
  )
}