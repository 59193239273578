import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import Loading from '../components/loading';


export default function PaymentDetails() {
    const { CompleteRequest } = useSelector(state => state.checkout)
    const { CompleteRequestResponse } = useSelector(state => state.checkout);

    console.log("CompleteRequest", CompleteRequest)
    console.log("CompleteRequestResponse", CompleteRequestResponse)

    if (CompleteRequestResponse.Data === null) {
        return <Loading />
    }

    let details = CompleteRequest.Products.map((product, index) => {
        const rateId = product.RateId;
        const correspondingResponse = CompleteRequestResponse.Data.Policies.find(product => {
            if (product.RateId === rateId) {
                return true;
            }
        });
        const document = correspondingResponse.Document || correspondingResponse.Url;
        const formattedPrice = new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(product.RetailPrice)
        const productString = `${product.Term} month ${product.Provider} ${product.ProductName} - ${formattedPrice}`;

        return (            
            <div key={`PurchasedProduct${index}`}>
                <p>{productString}</p>
                <a href={document} target="_blank" rel="noreferrer noopener" style={{paddingLeft: "25px"}}>View policy document</a>                    
            </div>            
        )
    })
    
return (
    <>  
        {CompleteRequestResponse.Data !== null && details.length === 1 ? <h1>Your purchase:</h1> : <h1>Your purchases:</h1>}
        {
            CompleteRequestResponse.Data !== null &&        
            <div className="checkout--container" style={{margin:"50px"}}>
                {details}
            </div>
        }
                
        <h1>You will receive a copy of this purchase to your email address.</h1>
        <br />
        <h1>Thank you for your purchase.</h1>
        <h1>You may now close this window.</h1>
    </>
)
}