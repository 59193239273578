import { useParams } from 'react-router-dom';
import { useConnect } from '../hooks/useConnect';

import Loading from '../components/loading';
import HeadBar from '../components/headbar';
import Content from '../components/content';
import Estimate from '../components/estimate--product-quote';
import Section from '../components/section';
import Eligibility from '../components/eligibility-covered-callout.js';
// import CardProduct from '../components/card--product';

import '../styles/components/product/index.scss';
import '../styles/components/paragraph.scss';

export default function ProductQuote() {
  const { id } = useParams();
  const Data = useConnect('external/GetProductItemAsync', { Id: id }, null);
  // const Products = useConnect('external/GetProductListingAsync', null, null);
  // const filteredProducts = Products.filter((product) => product.Id !== id);

  // Loop through products
  // See if id Exists, if it does remove
  // Output the remainder

  return (
    <>
      {Data ? (
        <>
          <HeadBar back={`/product/${id}`} />
          <div className='flow--4'>
            <h1 className='h1 bold'>Quote {Data.Name}</h1>
            {Data.TagLine ? <h2 className='h4 semi primary--200'>{Data.TagLine}</h2> : null}
          </div>
          <div className='features__thumbnail'>
            <picture className='ratio--16by9'>
              <img src={Data.ImageUrl} alt={Data.Name + ' | ' + Data.TagLine} />
            </picture>
          </div>
          {Data.QuoteTitle && Data.QuoteDescription ?
            <Content>
              <h3 className='h4 semi mt-32'>
                <span>{Data.QuoteTitle ? Data.QuoteTitle : null}</span>
              </h3>
              <p>
                <span>{Data.QuoteDescription ? Data.QuoteDescription : null}</span>
              </p>
            </Content>
            : null}
          <Estimate product={[id, Data.Name]} intro={Data.PricingDescription} data={Data.Pricing} />
          <Section>
            <Eligibility data={Data.PolicyInfo} />
          </Section>
        </>
      ) : (
        <Loading />
      )}
      {/* {Products ? (
        <div className='wrapper'>
          {Data.Products.map((product) => {
            return <CardProduct key={product} data={product} />;
          })}
        </div>
      ) : null} */}
    </>
  );
}
