import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setState } from '../../redux/checkout';
import "../../styles/components/dropdown.css"

export default function DropdownChange(Props) {
const [dropDownListValue, setdropDownListValue] = useState(Props.value);
const dispatch = useDispatch();

function handleChange(event) {
    setdropDownListValue(event.target.value);
    dispatch(setState({"root": Props.root, "key": Props.field, "value": event.target.value}));
}

return (
    <div className="DropdownChange">
        <label htmlFor={ Props.field }>{ Props.label }</label>
        <select name={ Props.field } value={dropDownListValue} onChange={handleChange} required={ Props.required }>
            {Object.keys(Props.value).map( (key, index) => (
            <option value={key} key={`DropdownChange${index}`}>
                {key}
            </option>
            ))}
        </select>
    </div>
);
}