import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setDealer, setGateway, setURI } from './redux/auth';
import { useLicense } from './hooks/useLicense';
import Menu from './pages/menu';

import convert from 'color-convert';

import './styles/base.scss';
import Loading from './components/loading';

export default function LicenseCheck(props) { 
  const dispatch = useDispatch();
  const Data = useLicense(props.license)

  const [loadingError, setLoadingError] = useState(false);

  useEffect(() => {
    let errorTimeout;

    if(Data) {
      // Redux store, when useLicense has data, store it in the respective containers
      dispatch(setDealer(props.license));
      dispatch(setGateway(Data.gateway));
      dispatch(setURI(Data.whitelistURI));

      var root = document.querySelector(':root');
      if(Data.primary_colour) {
        const hsl = convert.hex.hsl(Data.primary_colour);
        
        root.style.setProperty('--hue', hsl[0]);
        root.style.setProperty('--saturation', `${hsl[1]}%`);
        root.style.setProperty('--lightness', `${hsl[2]}%`);

        if(hsl[2] < 60) {
          root.style.setProperty('--standout', `hsl(${hsl[0]},10%,98%)`);
        } else {
          root.style.setProperty('--standout', `hsl(${hsl[0]},36%,8%)`);
        }
      }
    } else {
      errorTimeout = setTimeout(() => setLoadingError(true), 5000);
    }

    return () => {
      errorTimeout && clearTimeout(errorTimeout);
    }
  }, [dispatch, Data, props.license])

  if (loadingError) {
    return (
      <>
      <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
        <Loading />
        Cannot connect.
      </div>
      </>
    )
  }
  
  return (
    <>
    { Data ? <Menu /> : <Loading /> }
    </>
  )
}